import React from 'react';
import { Helmet } from 'react-helmet';

import { COLOR_COUNT_0 } from '../colors';
import { formatCountValue } from '../utils';

import { ChordDiagram, MicroBarChart, USHeatMap } from '../components/charts';
import { Row5050 } from '../components/grid';
import { CountMetric } from '../components/metrics';
import { Paper, PaperPadding, PaperTitle } from '../components/papers';
import { Ranking, RankingTable } from '../components/rankings';
import { AppLayout } from '../layout';

/**
 *
 */
/* eslint-disable-next-line max-lines-per-function */
export function States(props) {
  const { appData, charts, currentRanking, metrics } = props.pageContext;

  return (
    <AppLayout data={appData} title="States with the Most Tech Jobs">
      <Helmet>
        <title>States | StackTrends</title>
        <meta name="description" content="" />
      </Helmet>

      <div>
        <Row5050>
          <CountMetric value={`${formatCountValue(metrics.currentListingCount)} Listings`} label="Current" />

          <CountMetric value={`${formatCountValue(metrics.lifetimeListingCount)} Listings`} label="Historic" />
        </Row5050>

        <Paper>
          <PaperTitle>Job Listings Analyzed</PaperTitle>

          <PaperPadding>
            <MicroBarChart
              chartId="micro_bar_chart_states_listing_count"
              color={COLOR_COUNT_0}
              data={charts.microBarListingCount}
            />
          </PaperPadding>
        </Paper>
      </div>

      <Paper>
        <PaperTitle>Job Ranking by State</PaperTitle>

        <PaperPadding>
          <USHeatMap chartId="us_heat_map_states" data={charts.heatmapListingCount} />
        </PaperPadding>

        <Ranking>
          <RankingTable data={currentRanking} parentPath="states" />
        </Ranking>
      </Paper>

      <Row5050>
      <Paper>
          <PaperTitle>States that Compete for Tech Talent</PaperTitle>

          <PaperPadding>
            <ChordDiagram chartId="chord_diagram_state_shared_listings" data={charts.chordSharedListings} />
          </PaperPadding>
        </Paper>
      </Row5050>
    </AppLayout>
  );
}

export default States;

// {/* metrics: most jobs */}
// {metrics.currentMostJobs.map((item) => (
//   <DetailedMetric
//     text={`${item.count.toLocaleString()} Historic Jobs`}
//     title={'Most Jobs'}
//     values={[
//       <Link to={`/states/${item.name}`} key={item.id}>
//         {item.name_display}
//       </Link>
//     ]}
//   />
// ))}
//
// {/* metrics: fewest jobs */}
// {metrics.currentFewestJobs.map((item) => (
//   <DetailedMetric
//     text={`${item.count.toLocaleString()} Historic Jobs`}
//     title={'Fewest Jobs'}
//     values={[
//       <Link to={`/states/${item.name}`} key={item.id}>
//         {item.name_display}
//       </Link>
//     ]}
//   />
// ))}
//
// {/* metrics: gained the most ranks */}
// {metrics.currentRankWinners.map((item) => (
//   <DetailedMetric
//     text={`+${item.rankMovement.toLocaleString()} Ranks`}
//     title={'Gained the Most Ranks'}
//     values={[
//       <Link to={`/states/${item.name}`} key={item.id}>
//         {item.name_display}
//       </Link>
//     ]}
//     key={item.id}
//   />
// ))}
//
// {/* metrics: lost the most ranks */}
// {metrics.currentRankLosers.map((item) => (
//   <DetailedMetric
//     text={`${item.rankMovement.toLocaleString()} Ranks`}
//     title={'Lost the Most Ranks'}
//     values={[
//       <Link to={`/states/${item.name}`} key={item.id}>
//         {item.name_display}
//       </Link>
//     ]}
//     key={item.id}
//   />
// ))}
